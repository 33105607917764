var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "任务基本信息",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "formContentBox" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                disabled: _vm.queryType == "view",
                model: _vm.dataForm,
                "label-width": "150px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "模块名称：", prop: "moduleName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "show-word-limit": "",
                              maxlength: "60",
                              placeholder: "请填写模块名称",
                            },
                            model: {
                              value: _vm.dataForm.moduleName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "moduleName", $$v)
                              },
                              expression: "dataForm.moduleName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "负责人：", prop: "director" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "show-word-limit": "",
                              maxlength: "30",
                              placeholder: "请填写负责人",
                            },
                            model: {
                              value: _vm.dataForm.director,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "director", $$v)
                              },
                              expression: "dataForm.director",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "表编码：", prop: "tableName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "show-word-limit": "",
                              maxlength: "120",
                              placeholder: "请填写表编码",
                            },
                            model: {
                              value: _vm.dataForm.tableName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "tableName", $$v)
                              },
                              expression: "dataForm.tableName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "项目id字段：",
                            prop: "projectFields",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "show-word-limit": "",
                              maxlength: "60",
                              placeholder: "请填写项目id字段",
                            },
                            model: {
                              value: _vm.dataForm.projectFields,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "projectFields", $$v)
                              },
                              expression: "dataForm.projectFields",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "对应一级菜单：",
                            prop: "firstLevelMenu",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "show-word-limit": "",
                              maxlength: "60",
                              placeholder: "请填写对应一级菜单",
                            },
                            model: {
                              value: _vm.dataForm.firstLevelMenu,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "firstLevelMenu", $$v)
                              },
                              expression: "dataForm.firstLevelMenu",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序号：", prop: "soft" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "show-word-limit": "",
                              maxlength: "30",
                              placeholder: "请填写排序号",
                            },
                            model: {
                              value: _vm.dataForm.soft,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "soft", $$v)
                              },
                              expression: "dataForm.soft",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "配置类型：",
                            prop: "configurationType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.dataForm.configurationType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataForm,
                                    "configurationType",
                                    $$v
                                  )
                                },
                                expression: "dataForm.configurationType",
                              },
                            },
                            [_vm._v("语句")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              model: {
                                value: _vm.dataForm.configurationType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataForm,
                                    "configurationType",
                                    $$v
                                  )
                                },
                                expression: "dataForm.configurationType",
                              },
                            },
                            [_vm._v("接口")]
                          ),
                          _c("span", { staticStyle: { color: "blue" } }, [
                            _vm._v(
                              "若业务表在hse_prj库中可提供语句，若业务表不在hse_prj中请提供全路径api接口"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "配置内容：",
                            prop: "configurationStatement",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "sqlInput",
                            attrs: {
                              type: "textarea",
                              "show-word-limit": "",
                              placeholder: "请填写配置语句",
                            },
                            model: {
                              value: _vm.dataForm.configurationStatement,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm,
                                  "configurationStatement",
                                  $$v
                                )
                              },
                              expression: "dataForm.configurationStatement",
                            },
                          }),
                          _vm.dataForm.configurationType == 1
                            ? _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  "\n                示例： SELECT company_id projectId, max( company_name ) projectName,"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                count( 0 ) projectCount FROM hse_prj.dbt_before_class WHERE is_deleted = 0 "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                AND company_id IS NOT NULL AND company_name IS NOT NULL "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                GROUP BY company_id "
                                ),
                                _c("br"),
                                _vm._v("\n                说明： "),
                                _c("br"),
                                _vm._v("\n                项目id: projectId"),
                                _c("br"),
                                _vm._v(
                                  "\n                项目名称: projectName"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                各项目对应数据数量:projectCount\n              "
                                ),
                              ])
                            : _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  "\n                示例：接口内容为接口全路径 且请求类型为GET类型"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                例：http://192.168.0.180/api/sinoma-hse-prj/appliedtatistics/test "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                返回数据格式必须为 泛型R "
                                ),
                                _c("br"),
                                _vm._v(
                                  '\n                具体返回数据例子为{\n    "code": 200,\n    "success": true,\n    "data": [\n        {\n            "projectId": "1826071988621254658",\n            "projectCount": 3,\n            "projectName": "苏州中材梅州皇马日产4600吨熟料生产线及余热发电技改项目施工项目"\n        },\n        {\n            "projectId": "1827983315476389889",\n            "projectCount": 1,\n            "projectName": "远望提质"\n        },\n        {\n            "projectId": "1822097192476712962",\n            "projectCount": 6,\n            "projectName": "鄂尔多斯市东海新能源板链提升机供货合同"\n        }\n    ],\n    "msg": "操作成功"\n}\n              '
                                ),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }